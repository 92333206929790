<template>
    <template v-for="(item,index) in datalist" :key="item.id">
      <div style="padding:10px">{{index+1}}. <el-tag size="mini" effect="plain">{{item.questions.type}}</el-tag> {{item.questions.question}}</div>
      <div v-for="optionIt in item.options" :key="optionIt.k" style="padding:5px 10px">{{optionIt.k}}. {{optionIt.v}}</div>
      <div style="padding:5px 10px">
        正确答案：{{item.questions.correctAnswer}}<span style="margin-left: 20px;"></span>
        你的答案：
        <span v-if="item.questions.correctAnswer==item.answer" style="color:#52b091">{{item.answer}}</span>
        <span v-else style="color:#F00">{{item.answer}}</span>
      </div>
      <div style="padding:5px 10px">答案解析：{{item.questions.explain}}</div>
      <div style="height:2px;background-color: #eee;margin-top:10px"></div>
    </template>
</template>

<script>
export default {
  data() {
    return {
      id:0,
      datalist: [],
    };
  },
  mounted: function () {
    this.id = this.$route.params.id
    this.$net.exam(this.id).then(({ data }) => {
      this.datalist = data.exam.examAnswerList.map(it=>{
          it.answer = it.answer.replaceAll(',','')
          it.options = JSON.parse(it.questions.items.replace(/\t/g,''))
          return it
      })
    });
  }
};
</script>